.wifi {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #d5d5d5;
  margin: auto;
  animation: glow1 3s infinite;
}
@keyframes glow1 {
  19% {
    background-color: #d5d5d5;
  }
  20% {
    background-color: #fc5378;
  }
  100% {
    background-color: #fc5378;
  }
}

.wifi-outer {
  height: 50px;
  width: 50px;
  border: 10px solid transparent;
  border-top: 10px solid #d5d5d5;
  border-radius: 50%;
  position: relative;
  top: -15px;
  left: -20px;
  border-top-color: #d5d5d5;
  animation: glow2 3s infinite;
}
@keyframes glow2 {
  39% {
    border-top-color: #d5d5d5;
  }
  40% {
    border-top-color: #fc5378;
  }
  100% {
    border-top-color: #fc5378;
  }
}

.wifi-outer::before {
  position: relative;
  display: inline-block;
  content: "";
  width: 80px;
  height: 80px;
  border: 10px solid transparent;
  border-top: 10px solid #d5d5d5;
  border-radius: 50%;
  top: -25px;
  left: -25px;
  animation: glow3 3s infinite;
}
@keyframes glow3 {
  59% {
    border-top-color: #d5d5d5;
  }
  60% {
    border-top-color: #fc5378;
  }
  100% {
    border-top-color: #fc5378;
  }
}

.wifi-clear-animation {
  animation: none !important;
  background-color: #fc5378;
}

.wifi-outer-clear-animation {
  animation: none !important;
  border-top-color: #fc5378;
}

.wifi-outer-clear-animation::before {
  animation: none !important;
  border-top-color: #fc5378;
}

.present {
  background-color: green;
}

.absent {
  background-color: orange;
}

.bottle-filled::before {
  content: "✔";
  color: green;
  font-size: 18px;
}

.bottle-one-filled::after {
  content: "✔";
  color: green;
  font-size: 18px;
  position: relative;
  bottom: 30px;
}

.bottle-three-filled::after {
  content: "✔";
  color: green;
  font-size: 18px;
  position: relative;
  bottom: 30px;
}

.bottle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px dashed black;
  margin: auto;
}

.bottle-two {
  position: relative;
  left: -30px;
  bottom: -20px;
}

.bottle-four {
  position: relative;
  left: 25px;
  bottom: 25px;
}

.welcome-message {
  font-size: 20px;
}

.bottle-label {
  position: relative;
  top: -105px;
  font-size: 12px;
}

.instruction-photo {
  max-width: 100%;
}

.txt_next_steps {
  color: #9b9b9b;
}
