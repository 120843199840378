.reportContainer {
    width: 100%;
    padding: 16px;
    padding-top: 32px;
    padding-bottom: 32px;
    margin: 0% auto;
    border-radius: 16px;
    min-height: 500px;
    background-color: white;
    box-shadow: 0px 0px 20px #BBBBBB;
}

.flexBox {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
}
