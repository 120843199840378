.signatureContainer {
    border: 1px solid black;
    padding: 10px;
}

/* PDF-specific styles - these won't affect visual form layout */
@media print {
  .avoid-break {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
  }

  .page-break-before {
    break-before: page !important;
    page-break-before: always !important;
  }

  .page-break-after {
    break-after: page !important;
    page-break-after: always !important;
  }

  .form-section {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
  }

  .signature-section {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
  }

  .MuiFormControl-root {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
  }

  .major-section {
    break-before: page !important;
    page-break-before: always !important;
    margin-top: 1.5in !important;
  }

  .expandable-section {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
    margin-bottom: 1in !important;
  }

  /* Make text blocks more aggressive about staying together */
  p, Typography, label, .MuiFormLabel-root {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
    orphans: 3 !important;
    widows: 3 !important;
  }

  /* Force form controls to stay together */
  .MuiFormControl-root {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
    margin-bottom: 0.5in !important;
  }

  .address-section {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
    margin-bottom: 0.5in !important;
  }

  .acknowledgment-item {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
    display: block !important;
    margin-bottom: 0.25in !important;
  }

  /* Make form groups stay together */
  .MuiFormGroup-root {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
    margin-bottom: 0.25in !important;
  }
}