.accordion__single {
    border-bottom: 1px solid lightgray;
    margin-right: 20%;
    margin-left: 20%;
}

.accordion__single > * {
    font-family: MilliardBook;
}

.accordion__single__hidden {
    display: none;
}

.no-padding {
    padding: 0 !important;
    margin: 0 !important;
 }

.accordion__single__answer {
    opacity: 0;
    transform: translate(0, 50%);
    transition: all 0.4s ease;
    max-height: 0;
    margin: 0;
    color: #12334B;
}

.accordion__single__hidden:checked ~ .accordion__single__answer {
    opacity: 1;
    transform: translate(0, 0);
    max-height: 400px;
}

.accordion__single__question:hover, .accordion__single__question:focus, .accordion__single__question__label:hover, .accordion__single__question__label:focus {
    text-decoration: underline;
}

.accordion__single__question__label {
    position: relative;
    float: right;
    color: #12334B;
}

.accordion__single__question__label:after {
    content: "+";
}

.accordion__single__hidden:checked ~ .accordion__single__question__label:after {
    content: "-";
}

.faq-header {
    color: #12334B;
}

.accordion__single__question, .accordion__single__question__label {
    font-size: x-large;
}

.accordion__single__question {
    width: 90%;
    color: #12334B;
    font-weight: 500;
}