.App {
  background-image: url('onboarding_background.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 0;
  margin-top: -10px;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

.dark-btn { 
  background-color: #12334B;
  color: white;
  font-weight: 800;
  font-size: 14px;
  border-radius: 54px;
  padding: 10px 30px;
}

.light-btn { 
  background: linear-gradient(180deg, #FF6779 0%, #FC5390 100%);
  color: white;
  font-weight: 800;
  font-size: 14px;
  border-radius: 54px;
  padding: 15px 30px;
}

.light-btn:hover {
  color: white;
}

.dark-btn:hover {
  color: white;
}

.gem-container {
  min-width: 400px !important;
  margin-bottom: 25vw !important;
}

.form-container {
  margin: 0 auto;
  background-color: white;
  border: 2px solid #F8F8F8;
  box-sizing: border-box;
  box-shadow: 0px 25px 50px rgba(38, 73, 92, 0.15);
  border-radius: 8px;
  width: 30vw;
  min-width: 300px;
  max-width: 500px;
}

.form-container p {
  color: #12334B;
}

.form-container h2 {
  color: #12334B;
}

.form-container label {
  color: #12334B;
}

nav {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}

@media all and (max-width: 720px) {
  .App {
    background-image: url('onboarding_background_mobile.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  nav + .form-container {
    margin-top: 50px !important;
  }
  .gem-container {
    margin-top: 250px !important;
    min-width: 75vw !important;
  }
  nav {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .dark-btn {
    color: #12334B;
    font-size: 18px;
    font-weight: 500;
    border-radius: 0px;
    padding: 0;
    background: none;
    text-decoration-line: underline;
    line-height: 19px;
  }
  .dark-btn:hover {
    color: #12334B;
  }
  input {
    font-size: 16px !important;
  }
  input::placeholder {
    font-size: 16px;
  }
}

h1 {
  font-weight: 800;
  color: #12334B;
}

body > * {
  font-family: Avenir;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: MilliardBook;
  src: url('Milliard_Book.otf');
}

@font-face {
  font-family: MilliardBookMedium;
  src: url('Milliard_Book_Medium.otf');
}

.faded {
  background-color: red;
  opacity: 0.7;
}

.shimmer {
  border-radius: 10px;
  overflow: hidden;
  opacity: 0 !important;
  margin-left: auto;
  margin-right: auto;
}

.time-picker * {
  border: 0;
}
