@media all and (max-width: 719px) {
    .state-desktop {
        display: none;
    }
}

@media all and (min-width: 720px) {
    .state-mobile {
        display: none;
    }
}