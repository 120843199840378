.footer {
    min-height: 200px;
    background-color: #12334B;
}

.footer-link {
    color: white;
    padding-left: 1vw;
    padding-right: 1vw;
}

.footer-link:hover {
    text-decoration: none;
}

.footer-row {
    padding-left: 5vw;
    padding-right: 5vw;
}

.footer-img {
    padding-left: 1vw;
    padding-right: 1vw;
}

.footer-address {
    color: white;
    font-size: 14px;
    line-height: 1.5;
}

.footer-address p {
    margin: 0;
    padding: 0;
}

.footer-social-media,
.footer-social-media-mobile {
    margin-bottom: 40px;
}

@media all and (max-width: 719px) {
    .footer-links {
        display: none;
    }

    .footer-logo {
        display: none;
    }

    .footer-social-media {
        display: none;
        margin-bottom: 400px;
    }

    .footer {
        min-height: 300px;
    }

    .footer-break-mobile {
        border: thin solid white;
    }

    .footer-img {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}

@media all and (min-width: 720px) {
    .footer-logo-mobile {
        display: none;
    }

    .footer-social-media-mobile {
        display: none;
        margin-bottom: 400px;
    }

    .footer-break-mobile {
        display: none;
    }

    .footer-links-mobile {
        display: none;
    }

    .footer-mobile {
        display: none !important;
    }
}