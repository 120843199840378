.step {
    border-radius: 5%;
}

.step-image {
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
}

.step-title {
    color: #95ACB9;
    font-family: MilliardBook;
}

.step-text {
    font-family: MilliardBook;
    color: #12334B;
}

.step-header {
    color: #12334B;
}