.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

.gemFlexBox {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.gemOnboardFont {
    color: #505050;
    font-family: 'Manrope', sans-serif;
    text-align: center;
    font-weight: 400;
}

.gemOnboardFontSubHeader {
    color:#161616;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
}

.gemOnboardModalSubHeader {
    color:#161616;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    text-align: center;
}

.center {
    align-items: center;
    justify-content: center;
    margin-right: 12px,
}

.gemOnboardFontHeader {
    padding-top: 30px;
    color:#161616;
    font-family: 'Manrope', sans-serif;
    text-align: center;
    font-weight: bolder;
}

.gemOnboardButtonFont {
    font-family: 'Manrope', sans-serif;
    text-align: center;
}

.boldFont {
    font-weight: bold;
    padding-bottom: 12px;
}

.gemBody {
    all:initial;
}

.padding-0{
    padding-right:0px;
    padding-left:0px;
}

.ButtonSurvey{
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #FF6779 0%, #FC5390 100%);
    border-radius: 15px;
    border-color: #FC5390;
    width: 260px;
}

.welcomeRow{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.gridContainer{
    display:grid;
    justify-content: center;
    align-items: center;
}
.iconImage{
    display: inline-block;
    padding-right: 12px;
    max-width: 40px;
    max-height: 40px;
}

.paddingForText{
    padding-bottom: 27px;
    padding-left: 48px;
    padding-right: 48px;
}

.gemImage{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
    max-height: 75%;
    width: 50%;
}