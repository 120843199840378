.ggp-payment-options-subtle-text {
    font-size: 12px;
    color: #777;
    margin-bottom: 10px;
}

.ggp-payment-options-container {
    background-color: #F8F8F8;
    font-family: 'Manrope', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden; /* Prevent content overflow */
}

.ggp-payment-options-content {
    width: 80%;
    margin: auto;
    background-color: #F8F8F8;
    padding: 20px; /* Reduced padding */
    border-radius: 24px;
    flex: 1;
}
.ggp-payment-options-content-confirmation {
    width: 80%;
    margin: auto;
    background-color: #F8F8F8;
    padding: 20px;
    border-radius: 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
}

.ggp-payment-options-header {
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px; /* Adjusted font size */
}

.ggp-payment-options-plans {
    display: flex;
    justify-content: center; /* Center the plans */
    gap: 1rem;
}

.ggp-payment-options-plan {
    flex: 1;
    max-width: 300px; /* Set a fixed max width */
    padding: 1.5rem 1rem 1.5rem; /* Reduced padding */
    border-radius: 24px;
    text-align: left;
    background-color: white;
    box-shadow: 8px 10px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.2s; /* Add transition for hover effect */
}

.ggp-payment-options-plan-confirmation {
    flex: 1;
    max-width: 300px; /* Set a fixed max width */
    padding: 1.5rem 1rem 1.5rem; /* Reduced padding */
    border-radius: 24px;
    text-align: left;
    background-color: white;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
    border: 2px solid #ccc; /* Stronger, more visible border */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.2s, box-shadow 0.2s; /* Add transition for hover effect */
}
.ggp-payment-options-plan-confirmation:hover {
    transform: translateY(-10px); /* Move up slightly on hover */
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow on hover */
    border-color: #999; /* Darker border on hover */
}


.ggp-payment-options-plan:hover {
    transform: translateY(-10px); /* Move up slightly on hover */
}

.ggp-payment-options-title {
    color: #000;
    font-size: 25px; /* Reduced font size */
    font-weight: bold;
    margin-bottom: 10px;
    min-height: 50px; /* Ensure consistent height for the title */
}
/* Highlight styles */
.ggp-payment-options-highlight.copay {
    color: #d9534f; /* Refined crimson red */
    font-weight: 1500;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */

}

.ggp-payment-options-highlight.management {
    color: #337ab7; /* Professional deep blue */
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.ggp-payment-options-highlight.without-management {
    color: #5cb85c; /* Polished soft green */
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    background-color: rgba(92, 184, 92, 0.1); /* Light green background for highlight */
    padding: 2px 4px; /* Adds some padding around the text */
    border-radius: 4px; /* Rounds the corners of the background */
}

.ggp-payment-options-pricing {
    margin-bottom: 2.5px;
    margin-top: 5px;
    text-align: left;
}

.ggp-payment-options-pricing-strip {
    margin-bottom: 2.5px;
    margin-top: 5px;
    text-align: left;
}

.ggp-payment-options-price {
    font-size: 25px; /* Reduced font size */
    font-weight: 800;
    line-height: 1.2;
}

.ggp-payment-options-strip-price {
    font-size: 16px; /* Reduced font size */
    font-weight: bold;
}

.ggp-payment-options-per {
    font-size: 12px; /* Reduced font size */
    font-weight: normal;
}

.ggp-payment-options-refill-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: auto; /* Align refill info to the top */
    flex-grow: 1; /* Ensure refill info section grows to fill space */
}

.ggp-payment-options-refill-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.3em; /* Adjust the spacing as needed */
}

.ggp-payment-options-refill-item-last {
    display: flex;
    align-items: center;
    margin-bottom: 5px; /* Adjust the spacing as needed */
}

.ggp-payment-options-refill-mark {
    font-size: 20px; /* Reduced font size */
    color: #ff6779; /* Red color for the checkmark */
    margin-right: 8px; /* Adjust spacing between the mark and the text */
}

.ggp-payment-options-refill-text {
    font-size: 14px; /* Reduced font size */
    color: #555;
}

.ggp-payment-options-footer {
    margin-top: auto; /* Ensure footer stays at the bottom */
    width: 100%; /* Make sure the footer spans the entire width */
    margin-top: 10px;
}

.ggp-payment-options-select-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Make button span the full width */
    padding: 10px 15px; /* Adjusted padding */
    background-color: #12334b;
    color: white;
    text-decoration: none;
    border-radius: 27px;
    font-size: 14px; /* Reduced font size */
    font-weight: 600;
    border: none;
    cursor: pointer;
}

.ggp-payment-options-button-icon {
    background-color: #ff6779;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: 2rem; /* Reduced size */
    height: 2rem; /* Reduced size */
    display: flex;
    font-size: 18px; /* Reduced font size */
    margin-left: 5px; /* Adjusted margin to move arrow closer */
}

.ggp-payment-options-rules {
    text-align: center;
    font-size: 12px;
    color: #555;
    margin-top: 10px;
}

/* Modal Overlay */
.ggp-payment-options-modal-overlay {
    background-color: rgba(0, 0, 0, 0.6); /* Dark overlay with some transparency */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000; /* Ensure it's on top of other elements */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Modal Content */
.ggp-payment-options-modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 100%;
    z-index: 1100;
    font-family: 'Manrope', sans-serif;
}

/* Modal Header */
.ggp-payment-options-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

/* Modal Title */
.ggp-payment-options-modal-title {
    font-weight: 700;
    color: #12334b; /* Dark blue for a professional look */
}

/* Close Button */
.ggp-payment-options-modal-close-button {
    background-color: #12334b; /* Dark blue for buttons */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
}
.ggp-payment-options-modal-close-button-select {
    background-color: #12334b; /* Dark blue for buttons */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
}

/* Modal Body */
.ggp-payment-options-modal-body {
    font-size: 16px;
    color: #555555; /* Subtle text color */
    line-height: 1.5;
}

/* Modal Bullet Points */
.ggp-payment-options-modal-body ul {
    padding-left: 20px;
}

.ggp-payment-options-modal-body ul li {
    margin-bottom: 10px;
}

/* Modal Footer */
.ggp-payment-options-modal-footer {
    text-align: right;
    margin-top: 20px;
}

.ggp-payment-options-modal-footer-select {
    display: flex;
    justify-content: flex-start; /* Align buttons to the left */
    margin-top: 20px;
    gap: 10px; /* Add space between buttons if needed */
}


.ggp-payment-options-note {
    margin-top: 20px;
    padding: 10px;
    background-color: #f9f9f9; /* Light background to distinguish the note */
    border-radius: 8px;
    font-size: 14px;
    color: #555;
    text-align: center; /* Center align the text */
}



/* Media Queries */

/* General media query for screens smaller than 1200px */
@media (max-width: 1200px) {
    .ggp-payment-options-plans {
        flex-wrap: wrap; /* Allows wrapping of items */
    }
}

/* Switch to two containers per row at 1146px */
@media (max-width: 1146px) {
    .ggp-payment-options-plans {
        flex-wrap: wrap;
        justify-content: space-around; /* Ensure containers are evenly spaced */
    }

    .ggp-payment-options-plan {
        flex: 0 0 48%; /* Two items per row, with some margin between */
        max-width: 300px; /* Keeps the original width */
        width: 100%; /* Ensures the container doesn't exceed its parent */
        margin: 0 auto 20px; /* Centers the container and adds bottom margin */
    }
}

/* Switch to one container per row at 900px */
@media (max-width: 900px) {
    .ggp-payment-options-plans {
        flex-direction: column;
        align-items: center;
    }

    .ggp-payment-options-plan {
        flex: 0 0 auto; /* Ensures the container doesn't stretch */
        max-width: 300px; /* Keeps the original width */
        width: 100%; /* Ensures the container doesn't exceed its parent */
        margin: 0 auto 20px; /* Centers the container and adds bottom margin */
    }
}


