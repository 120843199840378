.ggp-select-plan-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
  }

  .ggp-select-plan-header {
   margin-bottom: 20px;
  }
  
  .ggp-select-plan-content {
    padding: 20px;
    text-align: center;
  }
  
  .ggp-select-plan-toggler {
    margin-top: 20px;
  }
  
  .ggp-select-plan-button {
    background-color: #003f87;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ggp-select-plan-icon {
    margin-left: 10px;
  }
  
  .ggp-select-plan-details-container {
    margin-top: 15px;
    text-align: left;
  }
  
  .ggp-select-plan-details {
    padding: 15px;
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  
  .ggp-select-plan-pricing {
    margin-top: 10px;
  }
  
  .ggp-select-plan-pricing-strip {
    margin-top: 5px;
  }
  
  .ggp-select-plan-subtle-text {
    color: #888;
  }
  
  .ggp-select-plan-refill-info {
    margin-top: 10px;
  }
  
  .ggp-select-plan-refill-item {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  
  .ggp-select-plan-refill-mark {
    color: #28a745;
    margin-right: 5px;
  }
  
  .ggp-best-regards {
    margin-bottom: 0px;
  }